! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(window).scroll(function() {
	jQuery('.header-section').toggleClass("sticky", jQuery(this).scrollTop() > 1);
});

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-section").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".dropdown-wrapper:has('.dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".dropdown-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dropdown-list",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{slug: "cta-animation",name: "CTA Animation",value: {style: {},triggers: [{type: "hover",selector: ".cta-content",descend: true,preserve3d: true,stepsA: [{opacity: 1,height: "auto",transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0,height: "0px",transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms",x: "0px",y: "20px",z: "0px"}]},{type: "hover",selector: ".cb-heading",descend: true,preserve3d: true,stepsA: [{opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0.8,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "33px",z: "0px"}]}]}},
		{slug: "left-nav-arrow-animation",name: "Left Nav Arrow Animation",value: {style: {},triggers: [{type: "hover",stepsA: [],stepsB: []}]}}
	]);
});
